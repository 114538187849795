import React, { useEffect } from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const root = createRoot(document.getElementById('root'));

function ThankYou() {

  useEffect(() => {
    const timer = setTimeout(() => {
      window.parent.postMessage("userleave", "*");
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div class="fullscreenDiv">
      <div class="center">
        <h2>Your session getting ended. Please wait...</h2>
      </div>
    </div>
  );
}

root.render(
  // <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/session-ended" element={<ThankYou />} />
        <Route path="/" element={<App />} />
      </Routes>
    </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
