import React, { useEffect } from 'react';

import './App.css';
import { ZoomMtg } from '@zoom/meetingsdk';
import { jwtDecode } from "jwt-decode";

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  var authEndpoint = 'https://apimeet.onlineprograms.in'
  // var sdkKey = "8lA2ICE6Q8mrKYN8TTHqw"
  // var meetingNumber = "92470899606"
  // var passWord = "718804"
  // var role = 0
  // var userName = "Ms. Y Sabithabanu"
  // var userEmail = "sabithabanu.math@drmgrdu.ac.in"
  // var registrantToken = ''
  // var zakToken = "eyJ0eXAiOiJKV1QiLCJzdiI6IjAwMDAwMSIsInptX3NrbSI6InptX28ybSIsImFsZyI6IkhTMjU2In0.eyJhdWQiOiJjbGllbnRzbSIsInVpZCI6ImxWYTktUFV2VDZXYnRvRHpLbmNOV3ciLCJpc3MiOiJ3ZWIiLCJzayI6IjIyMzAxMjE3OTY5NTA4NTk3MTYiLCJzdHkiOjEwMCwid2NkIjoiYXcxIiwiY2x0IjowLCJleHAiOjE3MTE5MjAwMDIsImlhdCI6MTcxMTkxMjgwMiwiYWlkIjoiVkJ2Y215d0hRME9na2F1bHBJT2IzZyIsImNpZCI6IiJ9.FkIS3nmZ7YVX_Ciru-B0zpfzligQR8SRqjEPAwb3wic"
  var signatureLocal = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBLZXkiOiI4bEEySUNFNlE4bXJLWU44VFRIcXciLCJzZGtLZXkiOiI4bEEySUNFNlE4bXJLWU44VFRIcXciLCJtbiI6IjkyNDcwODk5NjA2Iiwicm9sZSI6MSwiaWF0IjoxNzExOTE3NDExLCJleHAiOjE3MTE5MjQ2MTEsInRva2VuRXhwIjoxNzExOTI0NjExfQ.vCTe29SLbDl3nQFVcnD9xembvvLN3IZwtTwc9bhGlrQ"
  var leaveUrl = 'http://localhost:3000'

  function startMeeting(meetingNumber, passWord, userName, userEmail, sdkKey, signature, zak, customerKey) {
    console.log(meetingNumber, passWord, userName, userEmail, sdkKey, signature, zak);
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: `${window.location.origin}/session-ended`,
      patchJsMedia: true,
      success: (success) => {
        console.log(success)
        console.log('zoom init data', {
          signature,
          sdkKey,
          meetingNumber,
          passWord,
          userName,
          userEmail,
          tk: '',
          zak,
          customerKey
        });

        ZoomMtg.join({
          signature,
          sdkKey,
          meetingNumber,
          passWord,
          userName,
          userEmail,
          tk: '',
          zak,
          customerKey,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  useEffect(() => {
    async function getSignature() {
      // e.preventDefault();
      const { meetingNumber, passWord, userName, userEmail, sdkKey, signature, zakToken: zak, customerKey, role }  = await jwtDecode(token)
      // startMeeting(meetingNumber, passWord, userName, userEmail, sdkKey, signature, zak)
      fetch('https://apimeet.onlineprograms.in', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          meetingNumber,
          role: role
        })
      }).then(res => res.json())
      .then(response => {
        console.log('response', response)
        startMeeting(meetingNumber, passWord, userName, userEmail, sdkKey, response.signature, zak, customerKey)
      }).catch(error => {
        console.error(error)
      })
    }
    getSignature();
  }, [token])

  return (
    <div className="App">
      <main />
    </div>
  );
}

export default App;
